<template>
  <div class="container center">
    <v-header></v-header>
    <div class="browse-record">
      <van-pull-refresh v-model="browse.refreshing" @refresh="loadPageData(1,true,200)">
        <van-list v-show="browse.total>0"
                  offset="50"
                  :immediate-check="true"
                  :finished="!browse.hasMore"
                  finished-text="灵感到底了~"
                  v-model="browse.loading"
                  @load="loadPageData(browse.current+1,false)">
          <div class="browse-item" v-for="item in browse.records" :key="item.id" @click="goPlanDetail(item)">
            <div class="browse-item-time">{{item.createTime}}</div>
            <div class="browse-item-title">{{item.projectPlanTitle}}</div>
          </div>
        </van-list>
        <div class="scroll-empty" v-show="browse.total<=0 && !browse.loading">
          <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150" style="margin-bottom: 20px">
          <div>噢！这可能有点儿空 (⊙v⊙)</div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>


-------------------- script --------------------

<script>
  import {sleep} from "../../config/util";
  import {userApi} from "../../api/user";
  import {nav} from "@/config/nav";

  export default {
    data() {
      return {
        browse: {
          refreshing: false,
          total: 0,
          current: 1,
          size: 10,
          records: [],
          hasMore:false,
          loading:false
        }
      }
    },

    created() {
      this.loadPageData(1,true,200);
    },


    methods:{
      // 加载数据
      async loadPageData(current, clearRecords = false, minLoadingMs) {
        try {
          this.$loading('努力追溯中...');
          this.browse.loading = true
          await sleep(minLoadingMs || 0);
          const pageSize = this.browse.size;
          const params = {
            current, pageSize,
            size: pageSize,
          };

          const res = await userApi.findBrowseListByPage(params);
          if(res.code !== 1){
            return;
          }
          const data = res.data;
          this.browse.current = data.current;
          this.browse.pageSize = data.size;
          this.browse.total = data.total;
          if (clearRecords) {
            this.browse.records = data.records;
          } else {
            this.browse.records = [...this.browse.records, ...res.data.records];
          }
        } finally {
          this.browse.hasMore = this.browse.current * this.browse.size < this.browse.total;
          this.browse.refreshing = false;
          this.browse.loading = false
          this.$loading.close();
        }
      },


      goPlanDetail(item){
        nav.goPlanDetail(item.projectPlanId)
      }

    }


  }
</script>


-------------------- style --------------------

<style scoped lang="less">
    .browse-record {
      margin-top: var(--header-height);
    }

    .browse-item{
      padding: 16px 27px;
      font-size: 14px;
      overflow: hidden;
    }

    .browse-item-time{
      font-weight: bolder;
      margin-bottom: 4px;
    }

    .scroll-empty{
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: var(--grey-color);
    }
</style>
