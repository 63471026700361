import {planV2Api} from "@/api/v2/planV2Api";
import router from "@/router";
import Toast from "@/components/toast";


export const nav = {

  async goPlanDetail(planId){
    const isOnline = await planV2Api.findPlanIsOnline(planId)
    if(!isOnline){
      return Toast.info('方案不存在或已下架')
    }
    return router.push(`/caseDetail/${planId}`)
  }

}
